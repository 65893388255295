import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { CountryPagePropsType, lang, RawTravelInformationSections, TravelInfoData, RawTravelInformation } from '../../entities/queriesTypes'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { useGlobalStateContext } from '../../Context/site'
import PortableText from '../../components/sanityBlockContents/PortableText'
import Text from '../../components/atoms/Text';
import Accordion from "../../components/molecule/accordion";
import { formatTravelInfoData } from '../../utils'
import translations from '../../entities/translations';
import { countrySubMenu } from '../../utils/subNavigation'
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";


const IndexPage:React.FC<CountryPagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext?.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.country,
    subNavigationItem: countrySubMenu,
    subNavigationTitle: undefined
  })
  const { state } = useGlobalStateContext();
  const [travelInformationSections, setTravelInformationSections] = useState<TravelInfoData[]|null>();
  const pageID = translations.globals.subMenuTranslation;

  const getTitle = ( item : RawTravelInformation) => {
    return <Text type="H3" className="mb-0 text-granite-green">{item.title && item.title[state?.lang as lang]}</Text>
  }

  const getChildren = (item : RawTravelInformation) => {
    return item?.summary && item?.summary[state?.lang as lang]
     ? <PortableText content={item?.summary[state?.lang as lang]} 
      className="text-dove-gray" 
      template={{
        normal: (props: any) => <Text type="P" ptype="BASE" className="leading-6">{props.children}</Text>,
        p: (props: any) => <Text type="P" ptype="BASE" className="leading-6">{props.children}</Text>,
      }} /> : ''
  }

  useEffect(() => {
    if(page.travelInformationSections ) {
      const formatTravelInfo = (data: RawTravelInformationSections) => {
        delete data?._type;
        const _data = Object.entries(data);
        return formatTravelInfoData(_data);
      }
      const _data = formatTravelInfo(page.travelInformationSections);
      setTravelInformationSections(_data);
    }
  }, [page.travelInformationSections])

  return (
    <Layout seoTitle={(page?.metadata?.page_title && page?.metadata?.page_title[state?.lang as lang]) as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <Wrapper className="py-12" id={pageID.Reiseinfo[state?.lang as lang]!}>
      {page?.previousTripIntro?.[state?.lang as lang]
        && <PortableTextRenderer body={page?.previousTripIntro?.[state?.lang as lang]} />}

        {travelInformationSections?.length && 
          <Accordion accordionRecord={travelInformationSections || []} getTitle={getTitle} getChildren={getChildren} />}
      </Wrapper>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryTravelInfoPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      heroImage: _rawCountryTravelInformationImage(resolveReferences: {maxDepth: 4})
      travelInformationSections: _rawTravelInformationSections(resolveReferences: {maxDepth: 4})
      metadata: _rawCountryTravelInformationMetadata
    }
  }
`
